<template>
  <div class="user-activity d-flex flex-column">
    <CCard
      color="second-card-bg mb-0"
      class="ccard shadow-none"
    >
      <CCardHeader class="ccard-header">
        <span>{{ $t('lk.header.userActivity') }}</span>
        <CSpinner
          class="ml-3"
          v-if="loadingTable"
          color="primary"
          size="sm"
        />
      </CCardHeader>
      <CCardBody class="ccard-body d-flex flex-column">
        <CInput
          v-model="search"
          :placeholder="$t('general.inputPlaceholder')"
        >
          <template #prepend>
            <CButton class="shadow-none" color="primary">
              <CIcon name="cil-search"/>
              {{ $t('general.search') }}
            </CButton>
          </template>
        </CInput>

        <div class="grid-wrap">
          <div class="grid">
            <div
              v-for="(cell, i) in fields"
              :key="i"
              class="grid__cell grid__cell_header"
            >
              <div class="inner">
                {{ cell.label }}
              </div>
            </div>

            <template
              v-for="(item, i) in activityList"
              class="grid__row"
            >
              <div :key="'createdAt' + i" class="grid__cell">
                <div class="inner">
                  {{ item.created_at }}
                </div>
              </div>

              <div :key="'name' + i" class="grid__cell">
                <div class="inner">
                  {{ item.ip }}
                </div>
              </div>

              <div :key="'user_email' + i" class="grid__cell" @click="$emit('openAccessModal', item)">
                <div class="inner _cursor">
                  {{ item.user?.email }}
                </div>
              </div>

              <div :key="'user_name' + i" class="grid__cell">
                <div class="inner">
                  {{ item.user?.name }}
                </div>
              </div>

              <div :key="'url' + i" class="grid__cell">
                <div class="inner">
                  {{ item.url }}
                </div>
              </div>

<!--              <div :key="'meta' + i" class="grid__cell">-->
<!--                <div class="inner _cursor">-->
<!--                  {{ item.meta }}-->
<!--                </div>-->
<!--              </div>-->
            </template>
          </div>

        </div>
        <CPagination
          class="mt-2 pagination-custom"
          :class="{_disabled: loadingTable}"
          v-show="totalPages > 1"
          :activePage.sync="page"
          :pages="totalPages"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  name: "UserActivity",
  data() {
    return {
      search: null,
      loadingTable: false,
      activityList: [],
      page: 1,
      totalPages: null,
      loadingRequest: 0,
      fields: [
        {key: 'created_at', label: 'Дата входа'},
        {key: 'ip', label: 'IP'},
        {key: 'user_email', label: 'Email'},
        {key: 'user_name', label: 'Имя'},
        {key: 'url', label: 'Url'},
        // {key: 'meta', label: 'Meta'},
      ]
    }
  },
  computed: {
    locale() {
      return this.$root.$i18n.locale
    }
  },
  created() {
    this.getActivities()
  },
  watch: {
    search(val) {
      if (typeof val !== 'string') return

      if (val.length >= 3) {
        this.page = 1
        const params = {}
        if (val) params.term = val

        debounce(this.getActivities, 300)(params)
      } else if (val.length === 0) {
        debounce(this.getActivities, 300)()
      }
    },
    page(val, pldVal) {
      if (val && val !== pldVal) {
        this.getActivities()
      }
    }
  },
  methods: {
    getActivities(params) {
      this.loadingTable = true

      const paramsLocal = {
        ...params,
        page: this.page,
      }

      if (this.search) {
        paramsLocal.term = this.search
      }
      axios.get('api/v1/user-activity', {
        params: paramsLocal
      })
        .then((res) => {
          const {data, status} = res
          if (status < 300) {
            this.activityList = data.data
            this.page = data.meta.current_page
            this.totalPages = data.meta.last_page
          }
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
  }
}
</script>

<style scoped lang="scss">
.ccard {
  margin-bottom: 0;

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
    .grid__cell .inner {
      position: relative;
      padding: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 0 -2rem;

    .ccard-header {
      display: none;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-header {
      display: flex;
      align-items: center;
      padding: 1.25rem 2rem;
    }
  }
}

.input {
  width: 9rem;
}

.reset-date {
  cursor: pointer;
}

.grid-wrap {
  overflow: auto;
  width: 100%;
  height: 100%;
}

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  &__cell {
    border-top: 1px solid var(--grid-border);
    position: relative;
    justify-self: stretch;

    .inner {
      padding: 0.75rem;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &._cursor {
        cursor: pointer;
      }
    }

    &:hover {
      overflow: visible;
      //z-index: 2;
      .inner {
        width: unset;
        z-index: 10;
        background: var(--second-card-bg);
      }
    }

    &_header {
      min-height: calc(0.875rem * 1.5); // минимальная высота в 1 строку
      box-sizing: content-box;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      background: var(--second-card-bg);
      //border: none;
      position: sticky;
      top: 0;
      z-index: 10;

      &:hover {
        overflow: visible;
        //z-index: 11;
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-activity d-flex flex-column" },
    [
      _c(
        "CCard",
        {
          staticClass: "ccard shadow-none",
          attrs: { color: "second-card-bg mb-0" },
        },
        [
          _c(
            "CCardHeader",
            { staticClass: "ccard-header" },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("lk.header.userActivity")))]),
              _vm.loadingTable
                ? _c("CSpinner", {
                    staticClass: "ml-3",
                    attrs: { color: "primary", size: "sm" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "CCardBody",
            { staticClass: "ccard-body d-flex flex-column" },
            [
              _c("CInput", {
                attrs: { placeholder: _vm.$t("general.inputPlaceholder") },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function () {
                      return [
                        _c(
                          "CButton",
                          {
                            staticClass: "shadow-none",
                            attrs: { color: "primary" },
                          },
                          [
                            _c("CIcon", { attrs: { name: "cil-search" } }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("general.search")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("div", { staticClass: "grid-wrap" }, [
                _c(
                  "div",
                  { staticClass: "grid" },
                  [
                    _vm._l(_vm.fields, function (cell, i) {
                      return _c(
                        "div",
                        { key: i, staticClass: "grid__cell grid__cell_header" },
                        [
                          _c("div", { staticClass: "inner" }, [
                            _vm._v(" " + _vm._s(cell.label) + " "),
                          ]),
                        ]
                      )
                    }),
                    _vm._l(_vm.activityList, function (item, i) {
                      return [
                        _c(
                          "div",
                          { key: "createdAt" + i, staticClass: "grid__cell" },
                          [
                            _c("div", { staticClass: "inner" }, [
                              _vm._v(" " + _vm._s(item.created_at) + " "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { key: "name" + i, staticClass: "grid__cell" },
                          [
                            _c("div", { staticClass: "inner" }, [
                              _vm._v(" " + _vm._s(item.ip) + " "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            key: "user_email" + i,
                            staticClass: "grid__cell",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("openAccessModal", item)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "inner _cursor" }, [
                              _vm._v(" " + _vm._s(item.user?.email) + " "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { key: "user_name" + i, staticClass: "grid__cell" },
                          [
                            _c("div", { staticClass: "inner" }, [
                              _vm._v(" " + _vm._s(item.user?.name) + " "),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { key: "url" + i, staticClass: "grid__cell" },
                          [
                            _c("div", { staticClass: "inner" }, [
                              _vm._v(" " + _vm._s(item.url) + " "),
                            ]),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
              _c("CPagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalPages > 1,
                    expression: "totalPages > 1",
                  },
                ],
                staticClass: "mt-2 pagination-custom",
                class: { _disabled: _vm.loadingTable },
                attrs: { activePage: _vm.page, pages: _vm.totalPages },
                on: {
                  "update:activePage": function ($event) {
                    _vm.page = $event
                  },
                  "update:active-page": function ($event) {
                    _vm.page = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }